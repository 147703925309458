import React from "react";
import { isIos } from "./utils/index";
import isTouchDevice from "ismobilejs";
import siteConfig from "../data/SiteConfig";

const MOBILE_BREAKPOINT = 769;
const COOKIES_NOTICE_DELAY = 5000;

const defaultContextValue = {
  headerStickyShown: false,
  mobileMode: false,
  mobileMenuShown: false,
  modalShown: false,
  cookiesNoticeShown: false,
  prevUrl: "fromBeyond",
  touchDevice: false,

  toggleMobileMenu: () => {},
  toggleModal: () => {},
  toggleCookiesNotice: () => {},
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

class ContextProviderComponent extends React.Component {
  constructor() {
    super();

    this.controlHeaderSticky = this.controlHeaderSticky.bind(this);
    this.checkMobileSize = this.checkMobileSize.bind(this);
    this.controlMobileMode = this.controlMobileMode.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleCookiesNotice = this.toggleCookiesNotice.bind(this);
    this.ifCookiesNoticeAccepted = this.ifCookiesNoticeAccepted.bind(this);

    this.state = {
      ...defaultContextValue,
      toggleMobileMenu: this.toggleMobileMenu,
      toggleModal: this.toggleModal,
      toggleCookiesNotice: this.toggleCookiesNotice,
    }
  }

  componentDidMount() {
    this.setState({ touchDevice: isTouchDevice.any });

    this.controlHeaderSticky();
    window.addEventListener("scroll", () => {
      this.controlHeaderSticky();
    });

    this.controlMobileMode();
    window.addEventListener("resize", () => {
      this.controlMobileMode();
    });

    // If user hasn't accepted cookies storage yet
    if (!this.ifCookiesNoticeAccepted()) {
      setTimeout(
        () => this.toggleCookiesNotice(true),
        COOKIES_NOTICE_DELAY
      );
    }
  }
  controlHeaderSticky() {
    const { headerStickyShown, mobileMenuShown, mobileMode } = this.state;

    // On none-home pages: show sticky header on normal header scroll out
    const normalHeader = Array.from(document.getElementsByClassName("header")).filter(node => (
      !node.classList.contains("header--sticky")
    ))[0];
    const normalHeaderScrolledOut = normalHeader && normalHeader.getBoundingClientRect().bottom < 0;

    // On home page: show sticky header on first section scroll out
    const firstSection = document.getElementById("sFlag");
    const firstSectionScrolledOut = firstSection && firstSection.getBoundingClientRect().bottom < (mobileMode ? 100 : 150);

    const headerStickyShouldBeShown = firstSection ? firstSectionScrolledOut : normalHeaderScrolledOut;

    if (!headerStickyShown && headerStickyShouldBeShown) {
      this.setState({ headerStickyShown: true });
    } else if (headerStickyShown && !headerStickyShouldBeShown) {
      this.setState({ headerStickyShown: false });
    }

    if (mobileMenuShown) {
      this.toggleMobileMenu(false);             // always hide mobile menu on scrolling
    }
  };
  checkMobileSize() {
    return window.innerWidth < MOBILE_BREAKPOINT;
  }
  controlMobileMode() {
    const { mobileMode } = this.state;
    const mobileModeNew = this.checkMobileSize();

    if (mobileModeNew !== mobileMode) {
      this.setState({ mobileMode: mobileModeNew });
      if (!mobileModeNew) {
        this.toggleMobileMenu(false);           // hide mobile menu on screen resize from mobile to desktop
      }
    }
  }

  toggleMobileMenu(onOff) {
    // If onOff is undefined (not passed) then toggle from previous state.
    // If onOff is true or false then set passed value.
    this.setState(prevState => ({
      mobileMenuShown: onOff === undefined ? !prevState.mobileMenuShown : onOff
    }));
  }

  toggleModal(onOff) {
    if (((onOff !== undefined) && onOff) || ((onOff === undefined) && !this.state.modalShown)) {
      // On modal show

      this.setState({ prevUrl: window.location.pathname.replace(siteConfig.pathPrefix, "") });

      // Prevent body scrolling for non-iOS environments
      if (!isTouchDevice.any) {
        document.body.classList.add("noscroll");
      } else {
        // document.body.classList.add("nobody");
      }

      // If cookies notice is still shown on modal opening - close it.
      if (!this.ifCookiesNoticeAccepted()) {
        this.toggleCookiesNotice(false);
      }

      // Add iOS-specific styles for fix scrolling bugs
      if (isIos()) {
        window.document.getElementsByTagName("html")[0].classList.add("ios");
      }
    } else if (((onOff !== undefined) && !onOff) || ((onOff === undefined) && this.state.modalShown)) {
      // On modal hide

      // Restore body scrolling for non-iOS environments
      if (!isTouchDevice.any) {
        document.body.classList.remove("noscroll");
      } else {
        // document.body.classList.remove("nobody");
      }

      // Remove iOS-specific styles
      if (isIos()) {
        window.document.getElementsByTagName("html")[0].classList.remove("ios");
      }
    }

    // If onOff is undefined (not passed) then toggle from previous state.
    // If onOff is true or false then set passed value.
    this.setState(prevState => ({
      modalShown: onOff === undefined ? !prevState.modalShown : onOff
    }));
  }

  ifCookiesNoticeAccepted = () => (localStorage.getItem("cookies_using") === "accepted");
  toggleCookiesNotice = (onOff) => {
    this.setState({ cookiesNoticeShown: onOff });
    if (!onOff) {
      localStorage.setItem("cookies_using", "accepted");
    }
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

export { Consumer as default, ContextProviderComponent }
