module.exports = [{
      plugin: require('/Users/san/Sites/work/newis.ltd/front2/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":690},
    },{
      plugin: require('/Users/san/Sites/work/newis.ltd/front2/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#f3f3f3"},
    },{
      plugin: require('/Users/san/Sites/work/newis.ltd/front2/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/san/Sites/work/newis.ltd/front2/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/san/Sites/work/newis.ltd/front2/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/san/Sites/work/newis.ltd/front2/src/layout/index.jsx"},
    }]
