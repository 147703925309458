import React, { Component, Fragment } from "react";
import PropTypes from "react-proptypes";
import cx from "classnames";
import ContextConsumer from "../../Context";
import { graphql, Link, StaticQuery } from "gatsby";
import "./index.scss";
import HomeLink from "../HomeLink";
import Button from "../Button";
import IconFacebook from "../../img/icon-facebook.svg";
import IconMenu from "../../img/icon-menu.svg";
import IconClose from "../../img/icon-close.svg";
import IconMenuWhite from "../../img/icon-menu-white.svg";
import IconCloseWhite from "../../img/icon-close-white.svg";

class Header extends Component {
  render () {
    const { sticky, phone } = this.props;

    return (
      <ContextConsumer>
        {({ headerStickyShown, mobileMenuShown, toggleMobileMenu, toggleModal }) => {
          // Conditions to prevent displaying mobile menus of both sticky and normal headers at the same time
          const thisIsVisibleStickyHeader = headerStickyShown && sticky;
          const thisIsVisibleNormalHeader = !headerStickyShown && !sticky;

          return (
            <div className={cx("header", { "header--sticky": sticky })}>
              <div className="header__content">
                <div className="header__homelink_wrapper">
                  <HomeLink sticky={sticky} />
                </div>

                <nav className="header__links">
                  <Link to="/" className="header__link" activeClassName="header__link--active">HEM</Link>
                  <Link to="/faq" className="header__link" activeClassName="header__link--active">FAQ</Link>
                  <Link to="/kopvillkor" className="header__link" activeClassName="header__link--active">KÖPVILLKOR</Link>
                  <div className="header__links_divider" />
                  <a href="https://www.facebook.com/svenskmedborgare.se" target="_blank" className="header__link">
                    <img src={IconFacebook} className="header__fb_icon" alt="" />
                    {/*<span className="icon-facebook header__fb_icon" />*/}
                  </a>
                </nav>

                {!sticky ? (
                  <div className="header__phone header__phone--header">
                    {phone.frontmatter.title}
                    {!sticky && (
                      <div className="header__schedule" dangerouslySetInnerHTML={{__html: phone.html}}/>
                    )}
                  </div>
                ) : (
                  <div className="header__btn_wrapper">
                    <Button to="/order" shape="raised" content="ANSÖK NU" onClick={() => toggleModal(true)} />
                  </div>
                )}

                <div className={cx("header__mobile_btn_wrapper", { "header__mobile_btn_wrapper--sticky": sticky })}>
                  <button
                    className={cx("header__mobile_btn", { "header__mobile_btn--sticky": sticky })}
                    type="button"
                    onClick={() => toggleMobileMenu()}
                  >
                    <img
                      className="header__mobile_btn_icon"
                      src={!mobileMenuShown ? (sticky ? IconMenuWhite : IconMenu) : (sticky ? IconCloseWhite : IconClose)}
                      alt=""
                    />
                  </button>
                </div>
              </div>

              {(thisIsVisibleStickyHeader || thisIsVisibleNormalHeader) && (
                <div className={cx("mobile_menu", { "mobile_menu--shown": mobileMenuShown }, { "mobile_menu--sticky": sticky })}>
                  <nav className="mobile_menu__links">
                    <Link
                      to="/faq/"
                      className={cx("mobile_menu__link", { "mobile_menu__link--sticky" : sticky })}
                      activeClassName="mobile_menu__link--active"
                      onClick={() => toggleMobileMenu(false)}
                    >
                      FAQ
                    </Link>
                    <Link
                      to="/kopvillkor/"
                      className={cx("mobile_menu__link", { "mobile_menu__link--sticky" : sticky })}
                      activeClassName="mobile_menu__link--active"
                      onClick={() => toggleMobileMenu(false)}
                    >
                      Köpvillkor
                    </Link>
                  </nav>

                  <div className="mobile_menu__phone_wrapper">
                    <div className={cx("header__phone", { "header__phone--mobile_sticky": sticky })}>
                      {phone.frontmatter.title}
                      <div className={cx("header__schedule", { "header__schedule--mobile_sticky": sticky })} dangerouslySetInnerHTML={{__html: phone.html}}/>
                    </div>
                  </div>

                  <p className="mobile_menu__txt">
                    Ansök genom att klicka på knappen, svara på frågorna och fyll i dina uppgifter.
                  </p>

                  <div className="mobile_menu__btn_wrapper">
                    <Button
                      to="/order"
                      shape={sticky ? "flatBordered" : "raised"}
                      content="ANSÖK NU"
                      onClick={() => {
                        toggleModal(true);
                        toggleMobileMenu(false);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )
        }}
      </ContextConsumer>
    );
  }
}

Header.propTypes = {
  sticky: PropTypes.bool,
};

Header.defaultProps = {
  sticky: false,
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        phone: markdownRemark(fields: {name: {eq: "index"}, post: {eq: "phone"}}) {
          frontmatter {
            title
          }
          html
        }
      }
    `}
    render={data => <Header phone={data.phone} sticky={props.sticky} />}
  />
);
