// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-jsx": () => import("/Users/san/Sites/work/newis.ltd/front2/src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-pages-404-jsx": () => import("/Users/san/Sites/work/newis.ltd/front2/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/san/Sites/work/newis.ltd/front2/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-order-jsx": () => import("/Users/san/Sites/work/newis.ltd/front2/src/pages/order.jsx" /* webpackChunkName: "component---src-pages-order-jsx" */),
  "component---src-pages-success-jsx": () => import("/Users/san/Sites/work/newis.ltd/front2/src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/san/Sites/work/newis.ltd/front2/.cache/data.json")

