import React, { Component } from "react";
import PropTypes from 'react-proptypes';
import cx from "classnames";
import ContextConsumer from "../../Context";
import { Link } from "gatsby";
import "./index.scss";
import LogoColor from "../../img/logo-color.png";
import LogoWhite from "../../img/logo-white.png";

export default class HomeLink extends Component {
  render () {
    const { sticky } = this.props;

    return (
      <ContextConsumer>
        {({ mobileMode, toggleMobileMenu }) => (
          <Link to="/" className="homelink" onClick={() => toggleMobileMenu(false)}>
            <img
              src={sticky && mobileMode ? LogoWhite : LogoColor}
              alt="Logo"
              className={cx("homelink__img", { "homelink__img--sticky": sticky })}
            />

            <div className="homelink__text">
              <p
                className={cx(
                  "homelink__title",
                  { "homelink__title--sticky": sticky },
                )}
              >
                SVENSK<span className={cx({ "homelink__title--colored": !(sticky && mobileMode) })}>MEDBORGARE</span>

                {!sticky && <span className="homelink__subtitle">Bli svensk medborgare</span>}
              </p>
            </div>
          </Link>
        )}
      </ContextConsumer>
    );
  }
}

HomeLink.defaultProps = {
  sticky: false,
};

HomeLink.propTypes = {
  sticky: PropTypes.bool,
};
