function validateNotEmpty(value) {
  return (value.trim() !== "");
}
function validatePersonNumber(value) {
  // Shoud have format (yyyy|yy)mmdd-nnnn with optional "-nnnn" part (all digits)
  const reShort = /^\d{6}(-?\d{4})?$/;
  const reLong = /^\d{8}(-?\d{4})?$/;

  let valueTrimmed = value.replace(/[\s]+/g, ''); // do not care about spaces at all

  if (reShort.test(String(valueTrimmed).toLowerCase())) {
    valueTrimmed = '19' + valueTrimmed;
  } else if (!reLong.test(String(valueTrimmed).toLowerCase())) {
    return false;
  }

  // If format is OK: check date components
  const year = Number(valueTrimmed.slice(0, 4));
  const month = Number(valueTrimmed.slice(4, 6));
  const day = Number(valueTrimmed.slice(6, 8));

  const yearValid = (year > 1900) && (year <= new Date().getFullYear());
  const monthValid = (month > 0) && (month <= 12);
  const leapYear = (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0);

  let dayMaxValue = 0;
  switch (month) {
    case 1:
    case 3:
    case 5:
    case 8:
    case 10:
    case 12:
      dayMaxValue = 31;
      break;
    case 4:
    case 6:
    case 7:
    case 9:
    case 11:
      dayMaxValue = 30;
      break;
    case 2:
      dayMaxValue = leapYear ? 29 : 28;
      break;
  }
  const dayValid = (day > 0) && (day <= dayMaxValue);

  return (yearValid && monthValid && dayValid);
}
function validatePhone(value) {
  // Should contain only digits and separating symbols " ", "-", "/", "+", "."
  const re = /[\d -/+.]+/;
  return re.test(String(value.trim()).toLowerCase());
}
function validateEmail(value) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value.trim()).toLowerCase());
}

function getUrlParameter(name) {
  const newName = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + newName + "=([^&#]*)");
  const results = regex.exec(window.location.search);

  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function isIos() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;;
}

export {
  isIos,

  validateNotEmpty,
  validatePersonNumber,
  validatePhone,
  validateEmail,

  getUrlParameter,
};
