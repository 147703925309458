import React, { Fragment, Component } from "react";
import "./index.scss";
import ContextConsumer, { ContextProviderComponent } from "../Context";
import Helmet from "react-helmet";
import SEO from "../components/SEO/SEO";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Modal from "../components/Modal";
import Logo from "../../static/logos/logo-48-square.png";
import CookiesNotice from "../components/CookiesNotice";
import { navigate } from "gatsby";

export default class Layout extends Component {
  render() {
    const { children, pageResources, location } = this.props;
    // location.pathname is used on build stage only
    const page404 = pageResources ? !!(pageResources.page.path.match(/^\/404/)) : location.pathname.match(/^\/404/);
    const pageOrder = pageResources ? !!(pageResources.page.path.match(/\/order/)) : location.pathname.match(/\/order/);

    return (
      <ContextProviderComponent>
        <ContextConsumer>
          {({ headerStickyShown, prevUrl, touchDevice }) => {
            // If user opens website directly on "/order" page: redirect to homepage
            if (pageOrder && (prevUrl === "fromBeyond")) {
              return <NavigateHome />;
            }

            return (
              <div className="layout">
                <Helmet
                  meta={[
                    { name: "viewport", content: "width=device-width, initial-scale=1, maximum-scale=1" },
                  ]}
                  link={[
                    { rel: "shortcut icon", type: "image/png", href: Logo }
                  ]}
                />
                <SEO />

                {pageOrder ? (
                  children
                ) : (
                  <Fragment>
                    {!page404 && headerStickyShown && (
                      <Header sticky />
                    )}

                    {!page404 && (
                      <Header />
                    )}

                    <div className="layout__content">
                      {children}
                    </div>

                    <Footer page404={page404} />

                    <CookiesNotice />
                  </Fragment>
                )}

                {!touchDevice && <Modal />}
              </div>
            )
          }}
        </ContextConsumer>
      </ContextProviderComponent>
    );
  }
}

class NavigateHome extends Component {
  componentDidMount() {
    navigate("/");
  }
  render() {
    return (<div />);
  }
}
