const config = {
  siteTitle: "SvenskMedborgare.se", // Site title.
  siteTitleShort: "SvenskMedborgare.se", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "SvenskMedborgare.se", // Alternative site title for SEO.
  siteDescription: "Sveriges smidigaste sätt att ansöka om medborgarskap", // Website description used for RSS feeds/meta description tag.
  siteLogo: "/logos/logo-192-square.png", // Logo used for SEO and manifest.
  copyright: "© 2013 – 2019 Svenskmedborgare.se", // Copyright string for the footer of the website and RSS feed.

  siteFBAppID: "172271253412243", // FB Application ID for using app insights
  googleAnalyticsID: "UA-58861127-1", // GA tracking ID.

  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.

  themeColor: "#f3f3f3", // Used for setting manifest and progress theme colors.
  backgroundColor: "#006aa6", // Used for setting manifest background color.

  validate () {
    // Make sure pathPrefix is empty if not needed
    if (this.pathPrefix === "/") {
      this.pathPrefix = "";
    } else {
      // Make sure pathPrefix only contains the first forward slash
      this.pathPrefix = `/${this.pathPrefix.replace(/^\/|\/$/g, "")}`;
    }

    // Make sure siteUrl doesn't have an ending forward slash
    if (this.siteUrl.substr(-1) === "/")
      this.siteUrl = this.siteUrl.slice(0, -1);

    // Make sure siteRss has a starting forward slash
    if (this.siteRss && this.siteRss[0] !== "/")
      this.siteRss = `/${this.siteRss}`;
  }
};

module.exports = config;
