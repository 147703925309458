import React, { Component, Fragment } from "react";
import PropTypes from "react-proptypes";
import cx from "classnames";
import ContextConsumer from "../../Context";
import {graphql, Link, StaticQuery} from 'gatsby';
import "./index.scss";
import HomeLink from "../HomeLink";
import Button from "../Button";
import BittenredLogoColor from "../../img/bittenred-logo-color.png";
import BittenredLogoGray from "../../img/bittenred-logo-gray.png";
import IconFacebook from "../../img/icon-facebook.svg";
import IconArrowDown from "../../img/icon-arrow-down.svg";
import IconArrowUp from "../../img/icon-arrow-up.svg";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linksShown: false,
      contactsShown: false,
    };
  }

  toggleLinks = () => this.setState(prevState => ({ linksShown: !prevState.linksShown }));
  toggleContacts = () => this.setState(prevState => ({ contactsShown: !prevState.contactsShown }));

  render() {
    const { phone, page404 } = this.props;
    const { linksShown, contactsShown } = this.state;

    return (
      <ContextConsumer>
        {({ mobileMode, toggleModal }) => (
          <footer className="footer">
            <div className="footer__content">
              {!(page404 && !mobileMode) && (
                <Fragment>
                  {!(page404 && mobileMode) && (
                    <div className="footer__button_wrapper">
                      <HomeLink />
                    </div>
                  )}

                  <div className="footer__columns">
                    {!(page404 && mobileMode) && (
                      <div className="footer__column footer__column--medium">
                        <p className="footer__column_title">Bli svensk medborgare</p>

                        <p className="footer__column_text">
                          Ansök genom att klicka på knappen, svara på frågorna och fyll i dina uppgifter.
                        </p>

                        <div className="footer__button_wrapper">
                          <Button to="/order" shape="raised" content="ANSÖK NU" onClick={() => toggleModal(true)} />
                        </div>
                      </div>
                    )}

                    <div className={cx("footer__column footer__column--narrow", { "footer__column--border_top": (page404 && mobileMode) })}>
                      <div className="footer__column_title_wrapper" onClick={mobileMode ? this.toggleLinks : null}>
                        <p className={cx("footer__column_title", { "footer__column_title--mobile_shrinked": mobileMode && !linksShown })}>
                          Våra kunder
                        </p>

                        <img className="footer__column_title_toggle" src={linksShown ? IconArrowUp : IconArrowDown} alt="" />
                      </div>

                      {(!mobileMode || linksShown) && (
                        <Fragment>
                          {page404 && mobileMode && <Link to="/" className="footer__link">HEM</Link>}
                          <Link to="/faq" className="footer__link">FAQ</Link>
                          <Link to="/kopvillkor" className="footer__link">KÖPVILLKOR</Link>
                        </Fragment>
                      )}
                    </div>

                    <div className="footer__column footer__column--wide">
                      <div className="footer__column_title_wrapper" onClick={mobileMode ? this.toggleContacts : null}>
                        <p className={cx("footer__column_title", { "footer__column_title--mobile_shrinked": mobileMode && !contactsShown })}>
                          Kontakta oss
                        </p>

                        <img className="footer__column_title_toggle" src={contactsShown ? IconArrowUp : IconArrowDown} alt="" />
                      </div>

                      {(!mobileMode || contactsShown) && (
                        <div className="footer__infos">
                          <div className="footer__infos_block">
                            <div className="footer__info">
                              <p className="footer__info_label">Telefonnummer:</p>
                              <p className="footer__info_content footer__info_content--phone">{phone.frontmatter.title}</p>
                            </div>

                            <div className="footer__info">
                              <p className="footer__info_label">Telefontider:</p>
                              <div className="footer__info_content footer__info_content--schedule" dangerouslySetInnerHTML={{__html: phone.html}}/>
                            </div>
                          </div>

                          <div className="footer__infos_block">
                            <div className="footer__info">
                              <p className="footer__info_label">Email:</p>
                              <a className="footer__info_content footer__info_content--email footer__link" href="mailto:kontakt@svenskmedborgare.se">
                                kontakt@svenskmedborgare.se
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Fragment>
              )}

              <div className="footer__lowerline">
                <p className="footer__copyright">
                  {`© 2013 – ${new Date().getFullYear()} Svenskmedborgare.se`}
                </p>

                <a
                  href="https://www.facebook.com/svenskmedborgare.se"
                  target="_blank"
                  className="footer__fb"
                >
                  <img src={IconFacebook} className="footer__fb_icon" alt="" />
                </a>

                {page404 &&  (
                  <nav className="footer__nav">
                    <div className="footer__nav_divider" />
                    <Link to="/" className="footer__nav_link">HEM</Link>
                    <Link to="/faq" className="footer__nav_link">FAQ</Link>
                    <Link to="/kopvillkor" className="footer__nav_link">KÖPVILLKOR</Link>
                  </nav>
                )}

                <div className="footer__dev">
                  <p>Created by</p>
                  <img className="footer__dev_logo" src={mobileMode ? BittenredLogoColor : BittenredLogoGray} alt="" />
                  <p className="footer__dev_name">
                    bitten
                    <span className="footer__dev_name_color">red</span>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        )}
      </ContextConsumer>
    );
  }
}

Footer.propTypes = {
  page404: PropTypes.bool,
};

Footer.defaultProps = {
  page404: false,
};

export default ({ page404 }) => (
  <StaticQuery
    query={graphql`
      query {
        phone: markdownRemark(fields: {name: {eq: "index"}, post: {eq: "phone"}}) {
          frontmatter {
            title
          }
          html
        }
      }
    `}
    render={(data) => (
      <Footer phone={data.phone} page404={page404}/>
    )}
  />
);
