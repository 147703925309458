export function trackConversion(orderToken) {
  if (!window || (window && !window.gtag)) {
    return new Promise(resolve => setTimeout(resolve, 0));
  }

  let analytics = new Promise(resolve => {
    gtag('event', 'Order', {
      'event_category' : 'Survey Order',
      'event_label': 'Token: ' + orderToken,
      'event_callback': resolve
    });
  });

  let adwords = new Promise(resolve => {
    gtag('event', 'conversion', {
      'send_to': 'AW-977984556/MZkeCJyjoQcQrLir0gM',
      'value': 1.0,
      'currency': 'EUR',
      'event_callback': resolve
    });
  });

  return Promise.all([analytics, adwords]);
}
