import React, { Component } from "react";
import cx from "classnames";
import ContextConsumer from "../../Context";
import "./index.scss";
import Button from "../Button";

export default class CookiesNotice extends Component {
  render () {
    return (
      <ContextConsumer>
        {({ cookiesNoticeShown, toggleCookiesNotice }) => (
          <div className={cx("cnotice", {"cnotice--hidden": !cookiesNoticeShown })}>
            <div className="cnotice__content">
              <p className="cnotice__text">
                SvenskMedborgare.se använder kakor (cookies) för att webbplatsen ska fungera på ett bra sätt för dig.
                Genom att surfa vidare godkänner du att vi använder kakor.
              </p>

              <div className="cnotice__btn_wrapper">
                <Button
                  shape="flatBorderedSmall"
                  content={<span className="cnotice__btn_label">Jag förstår</span>}
                  onClick={() => toggleCookiesNotice(false)}
                />
              </div>
            </div>
          </div>
        )}
      </ContextConsumer>
    );
  }
}
