const countries = [
  "Afghanistan",
  "Åland",
  "Albanien",
  "Algeriet",
  "Amerikanska Jungfruöarna",
  "Amerikanska mindre utliggande öar",
  "Amerikanska Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarktis",
  "Antigua och Barbuda",
  "Argentina",
  "Armenien",
  "Aruba",
  "Australien",
  "Azerbajdzjan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belgien",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnien och Hercegovina",
  "Botswana",
  "Bouvetön",
  "Brasilien",
  "Brittiska Jungfruöarna",
  "Brunei",
  "Bulgarien",
  "Burkina Faso",
  "Burundi",
  "Caymanöarna",
  "Centralafrikanska Republiken",
  "Chad",
  "Chile",
  "Cocos (Keeling)-öarna",
  "Colombia",
  "Cooköarna",
  "Costa Rica",
  "Cypern",
  "Danmark",
  "Demokratiska republiken Kongo",
  "Djibouti",
  "Dominica",
  "Dominikanska republiken",
  "Ecuador",
  "Egypten",
  "Ekvatorialguinea",
  "El Salvador",
  "Elfenbenskusten",
  "Eritrea",
  "Estland",
  "Etiopien",
  "Falklandsöarna",
  "Färöarna",
  "Fiji",
  "Filippinerna",
  "Finland",
  "Förenade Arabemiraten",
  "Frankrike",
  "Franska Guyana",
  "Franska Polynesien",
  "Franska sydterritorierna",
  "Gabon",
  "Gambia",
  "Georgien",
  "Ghana",
  "Gibraltar",
  "Grekland",
  "Grenada",
  "Grönland",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard-och McDonaldsöarna",
  "Honduras",
  "Hongkong",
  "Indien",
  "Indonesien",
  "Irak",
  "Iran",
  "Irländska Republiken",
  "Island",
  "Isle of Man",
  "Israel",
  "Italien",
  "Jamaica",
  "Japan",
  "Jemen",
  "Jersey",
  "Jordanien",
  "Julön",
  "Kambodia",
  "Kamerun",
  "Kanada",
  "Kap Verde",
  "Kazakstan",
  "Kenya",
  "Kina",
  "Kirgizistan",
  "Kiribati",
  "Komorerna",
  "Kosovo",
  "Kroatien",
  "Kuba",
  "Kuwait",
  "Laos",
  "Lesotho",
  "Lettland",
  "Libanon",
  "Liberia",
  "Libyen",
  "Liechtenstein",
  "Litauen",
  "Luxemburg",
  "Macao",
  "Madagascar",
  "Makedonien",
  "Malawi",
  "Malaysia",
  "Maldiverna",
  "Mali",
  "Malta",
  "Marocko",
  "Marshallöarna",
  "Martinique",
  "Mauretanien",
  "Mauritius",
  "Mayotte",
  "Mexiko",
  "Mikronesien",
  "Moçambique",
  "Moldavien",
  "Monaco",
  "Mongoliet",
  "Montenegro",
  "Montserrat",
  "Myanmar",
  "Namibia",
  "Nederländerna",
  "Nederländska Antillerna",
  "Nepal",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Nordkorea",
  "Norfolkön",
  "Norge",
  "Norra Marianaöarna",
  "Nya Kaledonien",
  "Nya Zeeland",
  "Oman",
  "Österrike",
  "Östtimor",
  "Pakistan",
  "Palau",
  "Palestina",
  "Panama",
  "Papua Nya Guinea",
  "Paraguay",
  "Peru",
  "Pitcairn",
  "Polen",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republiken Kongo",
  "Reunion",
  "Rumänien",
  "Rwanda",
  "Ryssland",
  "Saint-Pierre och Miquelon",
  "San Marino",
  "Sankta Helena",
  "São Tomé och Príncipe",
  "Saudiarabien",
  "Schweiz",
  "Senegal",
  "Serbien",
  "Seychellerna",
  "Sierra Leone",
  "Singapore",
  "Slovakien",
  "Slovenien",
  "Solomonöarna",
  "Somalia",
  "Spanien",
  "Sri Lanka",
  "St. Kitts och Nevis",
  "St. Lucia",
  "St. Vincent And The Grena",
  "Storbritannien",
  "Sudan",
  "Surinam",
  "Svalbard och Jan Mayen",
  "Swaziland",
  "Sydafrika",
  "Sydgeorgien och Sydsandwhichöarna",
  "Sydkorea",
  "Syrien",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Tjeckien",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad och Tobago",
  "Tunisien",
  "Turkiet",
  "Turkmenistan",
  "Turks-och Caicosöarna",
  "Tuvalu",
  "Tyskland",
  "Uganda",
  "Ukraina",
  "Ungern",
  "United States Of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Västsahara",
  "Västsamoa",
  "Vatikanstaten",
  "Venezuela",
  "Vietnam",
  "Vitryssland",
  "Wallis-och Futunaöarna",
  "Zambia",
  "Zimbabwe",
];
  
const badCountries = [
  "Afghanistan",
  "Somalia",
];
export {
  countries,
  badCountries,
}
