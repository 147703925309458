import React, { Component, Fragment } from "react";
import PropTypes from "react-proptypes";
import cx from "classnames";
import "./index.scss";
import { Link } from "gatsby";
import ContextConsumer from "../../Context";

export default class Button extends Component {
  render () {
    const { shape, content, sublabel, withArrow, flagSection, disabled, onClick, submit, to } = this.props;
    let buttonShapeClass;
    switch (shape) {
      case "flat":
        buttonShapeClass = "btn--flat";
        break;
      case "flatBordered":
        buttonShapeClass = "btn--flat_bordered";
        break;
      case "flatBorderedSmall":
        buttonShapeClass = "btn--flat_bordered_small";
        break;
      case "flatBorderedTransparent":
        buttonShapeClass = "btn--flat_bordered_transparent";
        break;
      case "flatBorderedTransparent1":
        buttonShapeClass = "btn--flat_bordered_transparent_1";
        break;
      case "flatLighten":
        buttonShapeClass = "btn--flat_lighten";
        break;
      case "raised":
        buttonShapeClass = "btn--raised";
        break;
    }

    const elementProps = {
      onClick,
      className: cx(
        "btn",
        buttonShapeClass,
        { "btn--with_arrow": withArrow },
        { "btn--with_arrow_flag": flagSection },
        { "btn--disabled": disabled }
      ),
    };
    // Button element may submit a form
    if (!to) {
      elementProps.type = submit ? "submit" : "button";
    }

    const elementContent = (
      <Fragment>
        <span className="btn__label">{content}</span>
        {sublabel && <span className="btn__sublabel">{sublabel}</span>}
      </Fragment>
    );

    return (
      <ContextConsumer>
        {({ touchDevice }) => (
          (to && touchDevice) ? (
            /* If "to" attribute exists and device is touchable: create a Gatsby Link */
            <Link to={to} { ...elementProps }>{elementContent}</Link>
          ) : (
            /* Button doesn't allow Flexbox centering in Safari 10 so replace it with Div */
            <div { ...elementProps }>{elementContent}</div>
          )
        )}
      </ContextConsumer>
    );
  }
}

Button.propTypes = {
  shape: PropTypes.oneOf([
    "flat", "flatBordered", "flatBorderedSmall", "flatBorderedTransparent",
    "flatBorderedTransparent1", "flatLighten", "raised"
  ]).isRequired,
  content: PropTypes.node.isRequired,
  sublabel: PropTypes.string,
  withArrow: PropTypes.bool,
  flagSection: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  submit: PropTypes.bool,
  to: PropTypes.string,
};

Button.defaultProps = {
  sublabel: undefined,
  withArrow: false,
  flagSection: false,
  disabled: false,
  onClick: null,
  submit: false,
  to: null,
};
